/* eslint-disable no-console */
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GaAuthService } from '@ga/central-auth-angular';
import { RuntimeConfigurationService } from 'gain-web/shared-modules/runtime-configuration/runtime-configuration.service';

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss'],
  standalone: false,
})
export class SessionTimeoutDialogComponent {
  constructor(
    private authService: GaAuthService,
    private dialogRef: MatDialogRef<SessionTimeoutDialogComponent, boolean>,
    private runtimeConfig: RuntimeConfigurationService,
  ) {}

  logoutUser() {
    console.log('session logout triggered');
    this.authService.logout();
    this.dialogRef.close(false);
    window.location.href = this.runtimeConfig.get().authOptions.loginPageUrl;
  }

  reauthenticate() {
    const newWindow = window.open(
      this.runtimeConfig.get().authOptions.loginPageUrl,
      '_blank',
    );
    window.focus();

    this.authService.reauthenticate(() => {
      newWindow?.close();
      this.dialogRef.close(true);
    });
  }
}
