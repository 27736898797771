<mat-toolbar>
  <div>
    <img
      class="deloitte-ga-logo"
      alt="Deloitte GlobalAdvantage"
      src="assets/icons/header-logo.svg"
    />
    <mat-divider vertical></mat-divider>
    <a class="home-link" routerLink="/">Incentives</a>
  </div>
  <div>
    @if (clientId().length > 0) {
      <div class="search-section">
        <mat-form-field class="search-box" appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input
            type="text"
            matInput
            [formControl]="searchControl"
            [matAutocomplete]="auto"
            [placeholder]="'Search results'"
            (input)="onSearchInput($event)"
          />
          <button
            *ngIf="this.searchTerm() && this.searchTerm().length > 0"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSearch()"
            [attr.data-testid]="'clear-search-button'"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="onOptionSelected()"
          >
            @if (
              searchResults().BatchesResults.total === 0 &&
              searchResults().CalculationResults.total === 0 &&
              searchResults().TransactionsResults.total === 0
            ) {
              <mat-option
                *ngIf="this.searchTerm() && this.searchTerm().length > 0"
                class="no-results"
              >
                <div class="no-results-content">
                  <div class="search-title"><span>No results found</span></div>
                  <div class="search-links">
                    <span>Try searching in:</span>
                    <a
                      tabindex="0"
                      [routerLink]="[
                        '/clients',
                        clientPortalId(),
                        'calculations',
                        'batches',
                      ]"
                      [queryParams]="{ query: searchControl.value }"
                      class="view-link"
                      [attr.aria-label]="'View batches search results'"
                    >
                      Batches</a
                    >,
                    <a
                      tabindex="0"
                      [routerLink]="[
                        '/clients',
                        clientPortalId(),
                        'calculations',
                        'calculations-search',
                      ]"
                      [queryParams]="{ query: searchControl.value }"
                      class="view-link"
                      [attr.aria-label]="'View calculations search results'"
                    >
                      Calculations</a
                    >, or
                    <a
                      tabindex="0"
                      [routerLink]="[
                        '/clients',
                        clientPortalId(),
                        'calculations',
                        'transactions',
                      ]"
                      [queryParams]="{ query: searchControl.value }"
                      class="view-link"
                      [attr.aria-label]="'View transactions search results'"
                    >
                      Transactions
                    </a>
                  </div>
                </div>
              </mat-option>
            }
            @if (searchResults().BatchesResults.total > 0) {
              <mat-option class="section-header">
                <div class="section-header-content">
                  <span
                    >Batches ({{
                      searchResults().BatchesResults.total
                    }}
                    results)</span
                  >
                  <a
                    tabindex="0"
                    [routerLink]="[
                      '/clients',
                      clientPortalId(),
                      'calculations',
                      'batches',
                    ]"
                    [queryParams]="{ query: searchControl.value }"
                    class="view-link"
                    [attr.aria-label]="'View batches search results'"
                  >
                    View
                  </a>
                </div>
              </mat-option>
              <mat-option
                *ngFor="let option of searchResults().BatchesResults.batches"
                [value]="option"
              >
                <div class="details-card">
                  <div class="id-line">
                    <strong>ID:</strong>

                    <a
                      [routerLink]="[
                        '/clients',
                        clientPortalId(),
                        'calculations',
                        'batches',
                        option.BatchId,
                        'calculations',
                      ]"
                      class="search-record-details-link"
                      [attr.aria-label]="'View batch execution details'"
                    >
                      <span class="search-record-id-value"
                        >{{ option.BatchId | slice: 0 : 27 }}...</span
                      >
                    </a>
                  </div>
                </div>
              </mat-option>
            }
            @if (searchResults().CalculationResults.total > 0) {
              <mat-option class="section-header">
                <div class="section-header-content">
                  <span
                    >Calculations ({{
                      searchResults().CalculationResults.total
                    }}
                    results)</span
                  >
                  <a
                    tabindex="0"
                    [routerLink]="[
                      '/clients',
                      clientPortalId(),
                      'calculations',
                      'calculations-search',
                    ]"
                    [queryParams]="{ query: searchControl.value }"
                    class="view-link"
                    [attr.aria-label]="'View calculations search results'"
                  >
                    View
                  </a>
                </div>
              </mat-option>
              <mat-option
                *ngFor="
                  let option of searchResults().CalculationResults.calculations
                "
                [value]="option"
              >
                <div class="details-card">
                  <div class="id-line">
                    <strong>ID:</strong>
                    <a
                      [routerLink]="[
                        '/clients',
                        clientPortalId(),
                        'calculations',
                        'batches',
                        option.CalculationBatchID,
                        'calculations',
                        option.CalculationId,
                        'log',
                      ]"
                      class="search-record-details-link"
                      [attr.aria-label]="'View calculation execution details'"
                    >
                      <span class="search-record-id-value"
                        >{{ option.CalculationId | slice: 0 : 27 }}...</span
                      ></a
                    >
                  </div>
                  <div class="details-line">
                    <div>
                      <strong>EE:</strong>
                      <span class="search-record-value">{{
                        option.EE.length > 8
                          ? (option.EE | slice: 0 : 8) + "..."
                          : (option?.EE ?? " ")
                      }}</span>
                    </div>
                    <div>
                      <strong>EEID:</strong>
                      <span class="search-record-value">
                        {{
                          option.EEID && option.EEID.length > 8
                            ? (option.EEID | slice: 0 : 7) + "..."
                            : option.EEID
                        }}
                      </span>
                    </div>
                    <div>
                      <strong class="plan-type-label">Plan type:</strong>
                      <span class="search-record-value">{{
                        option.PlanType | planTypeDisplay: "short display name"
                      }}</span>
                    </div>
                  </div>
                </div>
              </mat-option>
            }
            @if (searchResults().TransactionsResults.total > 0) {
              <mat-option class="section-header">
                <div class="section-header-content">
                  <span
                    >Transactions ({{
                      searchResults().TransactionsResults.total
                    }}
                    results)</span
                  >
                  <a
                    tabindex="0"
                    [routerLink]="[
                      '/clients',
                      clientPortalId(),
                      'calculations',
                      'transactions',
                    ]"
                    [queryParams]="{ query: searchControl.value }"
                    class="view-link"
                    [attr.aria-label]="'View transactions search results'"
                  >
                    View
                  </a>
                </div>
              </mat-option>
              <mat-option
                *ngFor="
                  let option of searchResults().TransactionsResults.transations
                "
                [value]="option"
              >
                <div class="details-card">
                  <div class="id-line">
                    <strong>GUID:</strong>
                    <a
                      [routerLink]="[
                        '/clients',
                        clientPortalId(),
                        'calculations',
                        option.TransactionCalculationId,
                        'results',
                        'transactions',
                        option.GUID,
                      ]"
                      class="search-record-details-link"
                      [attr.aria-label]="'View calculation execution details'"
                    >
                      <span class="search-record-id-value"
                        >{{ option.GUID | slice: 0 : 27 }}...</span
                      >
                    </a>
                  </div>
                  <div class="details-line">
                    <div>
                      <strong>EE:</strong>
                      <span class="search-record-value">{{
                        option.EE.length > 8
                          ? (option.EE | slice: 0 : 8) + "..."
                          : (option?.EE ?? " ")
                      }}</span>
                    </div>
                    <div>
                      <strong>EEID:</strong>
                      <span class="search-record-value">
                        {{
                          option.EEID && option.EEID.length > 8
                            ? (option.EEID | slice: 0 : 7) + "..."
                            : option.EEID
                        }}
                      </span>
                    </div>
                    <div>
                      <strong class="plan-type-label">Plan type:</strong>
                      <span class="search-record-value">{{
                        option.PlanType | planTypeDisplay: "short display name"
                      }}</span>
                    </div>
                  </div>
                </div>
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    }
    <div class="link-icon">
      <a [href]="portalHomeUrl">
        <mat-icon>home</mat-icon>
      </a>
    </div>
    <div class="link-icon">
      <a [href]="portalHelpUrl">
        <mat-icon>help</mat-icon>
      </a>
    </div>
    <div
      class="link-icon link-dropdown"
      #userMenuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="userMenu"
    >
      <mat-icon>person</mat-icon>
      <mat-icon class="person-icon--inline"
        >{{ userMenuTrigger.menuOpen ? "arrow_drop_up" : "arrow_drop_down" }}
      </mat-icon>
    </div>
    <mat-menu #userMenu="matMenu" xPosition="before">
      <button
        mat-menu-item
        [attr.data-testid]="'logout-button'"
        (click)="handleLogout()"
      >
        Log out
      </button>
    </mat-menu>
    <div
      class="apps-menu link-icon link-dropdown"
      [matMenuTriggerFor]="appsMenu"
    >
      Menu
      <mat-icon>apps</mat-icon>
    </div>

    <mat-menu #appsMenu="matMenu" xPosition="before" class="nav-dropdown">
      <div class="dropdown--inline">
        <ng-container *ngFor="let tile of menuItems">
          <ng-container
            *ngIf="
              tile.tileType === 'TOOL_GROUP' && tile.tiles!.length >= 2;
              else noSubmenu
            "
          >
            <button mat-menu-item [mat-menu-trigger-for]="subMenu">
              {{ tile.tileName }}
            </button>
            <mat-menu
              #subMenu="matMenu"
              xPosition="after"
              [overlapTrigger]="false"
            >
              <button
                mat-menu-item
                *ngFor="let submenuTile of tile?.tiles"
                (click)="menuTileClicked(submenuTile)"
              >
                {{ submenuTile.tileName }}
              </button>
            </mat-menu>
          </ng-container>
          <ng-template #noSubmenu>
            <button mat-menu-item (click)="menuTileClicked(tile)">
              {{ tile.tileName }}
            </button>
          </ng-template>
        </ng-container>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
