import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  WritableSignal,
  signal,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GaAuthService } from '@ga/central-auth-angular';
import { ClientContextService } from 'gain-web/app/clients/client-shared/client-context.service';
import { environment } from 'gain-web/environments/environment';
import { RuntimeConfigurationService } from 'gain-web/shared-modules/runtime-configuration/runtime-configuration.service';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

export interface BatchesDetails {
  BatchId: string;
}
export interface CalculationDetails {
  CalculationId: string;
  EE: string;
  EEID: string | null;
  PlanType: ApiClient.PlanType | null | undefined;
  CalculationBatchID: string | null;
}

export interface TransactionDetails {
  GUID: string;
  TransactionId: string;
  TransactionCalculationId: string;
  EE: string;
  EEID: string;
  PlanType: ApiClient.PlanType | null | undefined;
}

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TopNavigationComponent implements OnInit, OnDestroy {
  loginPageUrl: string;
  logoutPageUrl: string;
  portalHomeUrl: string;
  portalHelpUrl: string;
  menuItems: ApiClient.PortalAppTilesDto[] | null = null;
  _onDestroy$ = new Subject<void>();
  searchControl = new FormControl('');
  searchTerm: WritableSignal<string> = signal('');
  clientId: WritableSignal<string> = signal('');
  clientPortalId: WritableSignal<string> = signal('');
  searchResults = signal({
    BatchesResults: {
      batches: [] as BatchesDetails[],
      total: 0,
    },
    CalculationResults: {
      calculations: [] as CalculationDetails[],
      total: 0,
    },
    TransactionsResults: {
      transations: [] as TransactionDetails[],
      total: 0,
    },
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _authService: GaAuthService,
    private _config: RuntimeConfigurationService,
    private menuService: ApiClient.MenuService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private _clientContext: ClientContextService,
  ) {
    const { loginPageUrl, logoutPageUrl, portalHomeUrl, portalHelpUrl } =
      this._config.get().authOptions;
    this.loginPageUrl = loginPageUrl;
    this.logoutPageUrl = logoutPageUrl;
    this.portalHomeUrl = portalHomeUrl;
    this.portalHelpUrl = portalHelpUrl;
  }

  async handleLogout() {
    await this._authService.logout();
    window.location.href = this.logoutPageUrl + '?allowLogout=true';
  }

  async ngOnInit() {
    this.router.events
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() => {
        this.clientId.set('');
        try {
          this.clientId.set(this._clientContext?.value?.client?.id ?? '');
          this.clientPortalId.set(
            this._clientContext?.value?.client?.portalId ?? '',
          );
        } catch (error) {
          // If the client context is not available it will throw an error. So we need to ignore it.
        }
      });
    if (environment.production) {
      const result = await this.menuService.getPortalAppTiles().toPromise();
      this.menuItems =
        result?.portalAppsData?.tiles.filter(
          (x) => x.tileType !== 'PORTLET' || !x.portletInfo,
        ) ?? null;
    }
  }

  menuTileClicked(item: ApiClient.PortalAppTilesDto) {
    let isTool = false;
    let id = null;

    if (!item.productId || item.productId === -1) {
      isTool = false;
      id = item.tileId;
    } else {
      isTool = true;
      id = item.productId;
    }
    if (id == null) return;
    this.menuService
      .portalTileOrToolClickedResult(
        new ApiClient.PortalTileOrToolClickedQuery({
          id: id.toString(),
          isTool,
        }),
      )
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        () => {
          this.menuRedirect(item);
        },

        function (error) {
          console.error(error);
        },
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  menuRedirect(item: ApiClient.PortalAppTilesDto) {
    this.triggerSamlService(item);
  }

  triggerSamlService(tile: ApiClient.PortalAppTilesDto) {
    const item =
      tile.tileType === 'TOOL_GROUP' && tile.tiles?.length === 1
        ? tile.tiles[0]
        : tile;

    this.menuService
      .postSAMLData(
        new ApiClient.PostSAMLDataQuery({
          redirectUrl: item.navUrl ?? '',
          toolId: item.tileId ?? '',
        }),
      )
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        (result) => {
          if (item.navUrl) {
            const form = document.createElement('form');
            form.action = item.navUrl;
            form.target = '_blank';
            form.method = 'POST';
            form.setAttribute('name', 'SAMLResponsesForm');
            const formInput = document.createElement('input');
            formInput.type = 'hidden';
            formInput.name = 'SAMLResponse';
            formInput.setAttribute('value', result.token);
            form.append(formInput);
            document.body.appendChild(form);
            form.submit();
          }
        },

        (error) => {
          console.error(error);
        },
      );
  }

  onSearchInput(event: Event): void {
    this.searchTerm.set((event.target as HTMLInputElement).value);
    //check if search term is not empty or only whitespace
    if (
      (this.searchTerm() || this.searchTerm().trim() !== '') &&
      this.clientId
    ) {
      this.menuService
        .search(
          new ApiClient.SearchCommand({
            clientId: this.clientId(),
            search:
              new ApiClient.SearchBarArgs({
                query: this.searchTerm().trim().toLowerCase() ?? null,
              }) ?? null,
            pagination: new ApiClient.SearchPaginationArgs({
              page: 1,
              pageSize: 3,
            }),
          }),
        )
        .pipe(debounceTime(500), takeUntil(this._onDestroy$))
        .subscribe((data: ApiClient.GlobalSearchCommandResultSuccess) => {
          // If the results are empty, set the signal to an empty array
          if (
            !data.results ||
            (!data.results.batchSearchResults &&
              !data.results.calculationSearchResults &&
              !data.results.transactionSearchResults)
          ) {
            this.searchResults.set({
              BatchesResults: { batches: [], total: 0 },
              CalculationResults: { calculations: [], total: 0 },
              TransactionsResults: { transations: [], total: 0 },
            });
            return;
          }
          this.searchResults.set({
            BatchesResults: {
              batches:
                data.results.batchSearchResults?.items.map((item) => ({
                  BatchId: item.id,
                })) ?? [],
              total: data.results?.batchSearchResults?.totalItems ?? 0,
            },
            CalculationResults: {
              calculations:
                data.results.calculationSearchResults?.items.map((item) => ({
                  CalculationId: item.id,
                  EE:
                    item.employeeFirstName ??
                    '' + ' ' + item.employeeLastName ??
                    '',
                  EEID: item.employeeId,
                  PlanType:
                    item.planTypeCounts?.map(
                      (planType) =>
                        planType.planType as
                          | ApiClient.PlanType
                          | null
                          | undefined,
                    )[0] ?? null,
                  CalculationBatchID: item.batchId ?? null,
                })) ?? [],
              total: data.results.calculationSearchResults?.totalItems ?? 0,
            },
            TransactionsResults: {
              transations:
                data.results.transactionSearchResults?.items.map((item) => ({
                  GUID: item.transactionGuid,
                  TransactionId: item.transactionId,
                  EE:
                    item.employeeFirstName ??
                    '' + ' ' + item.employeeLastName ??
                    '',
                  EEID: item.employeeId,
                  PlanType: item.planType as unknown as
                    | ApiClient.PlanType
                    | null
                    | undefined,
                  TransactionCalculationId: item.calculationId,
                })) ?? [],
              total: data.results.transactionSearchResults?.totalItems ?? 0,
            },
          });
          this.changeDetectorRef.detectChanges();
        });
    }

    if (this.searchTerm() === null || this.searchTerm().trim() === '') {
      // Reset the search results if the input is empty or only whitespace
      this.resetSearch();
    }
  }

  clearSearch(): void {
    this.resetSearch();
  }

  resetSearch(): void {
    this.searchControl.setValue('');
    this.searchTerm.set('');
    this.searchResults.set({
      BatchesResults: { batches: [], total: 0 },
      CalculationResults: { calculations: [], total: 0 },
      TransactionsResults: { transations: [], total: 0 },
    });
    this.changeDetectorRef.detectChanges();
  }

  onOptionSelected(): void {
    this.resetSearch();
  }
}
